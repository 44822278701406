<template>
    <div class="ProfileBox">
        <h2>{{ this.risorse("VecchiaPassword")}}</h2>
        <div class="Form">
            <div class="FormRow">
                <div class="FormBoxWide FormInputText">
                    <div class="Label">{{ risorse("VecchiaPassword") }}</div>
                    <div class="Field"><input type="password" v-model="this.oldPassword"/></div>
                </div>
            </div>
            <div class="FormRow">
                <div class="FormBoxWide FormInputText">
                    <div class="Label">{{ risorse("NuovaPassword") }}</div>
                    <div class="Field"><input type="password" v-model="this.newPassword"/></div>
                </div>
            </div>
            <div class="FormRow">
                <div class="FormBoxWide FormInputText">
                    <div class="Label">{{ risorse("ConfPassword") }}</div>
                    <div class="Field"><input type="password" v-model="this.repNewPassword"/></div>
                    <p class="PassNetEqual" v-show="this.showPassError || this.showPassErrorNe">{{ this.passErrorMessage }}</p>
                </div>
            </div>
            <div class="WhiteSpace">&nbsp;</div>
            <div class="FormRow">
                <div class="FormBoxWide FormInputButton">
                    <div class="Field Button TwinButtons">
                        <a href="javascript:void(0)" @click="this.resetData()">{{ risorse("Reset")}}</a>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a href="javascript:void(0)" @click="this.cambiaPassword()">{{ risorse("CambiaPassword")}}</a>
                    </div> 
                </div>
            </div>
        </div>
        <!--<div class="Form">
            <div class="WhiteSpace">&nbsp;</div>
            <div class="loader" v-show="this.showLoader"></div> 
            <div class="Alert Good" v-show="this.showSuccess">
                <div class="AlertTitle">{{ risorse("CambiaPswOk")}}</div>
                <div class="AlertText">{{ risorse("CambiaPswOkText")}}</div>
            </div>

            <div class="Alert Bad" v-show="this.showError">
                <div class="AlertTitle">{{ risorse("MessKO")}}</div>
                <div class="AlertText">{{this.apiError}}</div>
            </div>
        </div>-->
    </div>
</template>

<script>

import AuthService from "@/services/auth.service.js";
import CambioPasswordData from "@/models/CambioPasswordData.model.js";
import {risorseMixin} from "@/mixin.js";

export default {
    name: "CambiaPassword",
    data() {
        return {
            newPassword: "",
            repNewPassword: "",
            oldPassword: "",
            showSuccess: false,
            showError: false,
            showPassError: false,
            passErrorMessage: "",
            apiError: "",
            showPassErrorNe: false            
        }
    },
    watch: {
        newPassword: function(newVal){                
            if(this.newPassword && this.repNewPassword && this.oldPassword){
                this.showPassError = false;
            }
            if(newVal == this.repNewPassword){
                this.showPassErrorNe = false;
            }
        },
        repNewPassword: function(newVal){                
            if(this.newPassword && this.repNewPassword && this.oldPassword){
                this.showPassError = false;
            }
            if(this.newPassword == newVal){
                this.showPassErrorNe = false;
            }
        },
        oldPassword: function(newVal){                
            if(this.newPassword && this.repNewPassword && this.oldPassword){
                this.showPassError = false;
            }
        }
    },
    mixins:[risorseMixin],
    methods: {
        resetData(){
            this.oldPassword = "";
            this.newPassword = "";
            this.repNewPassword = "";            
            this.resetMessages();
        },
        resetMessages(){
            this.showPassError = false;
            this.showPassErrorNe = false;
            this.passErrorMessage = "";
            this.showError = false;
            this.showSuccess = false;
            this.apiError = ""
        },       
        cambiaPassword(){
            
            this.resetMessages();
            if(!this.newPassword || !this.oldPassword || !this.repNewPassword){
                this.showPassError = true;
                this.passErrorMessage =  risorse("ParametroMancante");
                return;
            }
            if(this.newPassword !=  this.repNewPassword){
                this.showPassErrorNe = true;
                this.passErrorMessage = risorse("PswNonUguali");
                return;
            }          
            this.$store.dispatch({
                type: 'setLoader',
                value: true
            });
            
            AuthService.cambioPassword(this.$store.getters.getToken, new CambioPasswordData(this.newPassword, this.repNewPassword, this.oldPassword), this.$store.getters.getShop).then(
                (data) => {
                    this.$store.dispatch({
                        type: 'setLoader',
                        value: false
                    });
                    if(data.status != 200){
                        if(data.status == 400 && data.data.Message == "KO_EQPASS"){
                            this.apiError = risorse("PswNonUguali");
                        }
                        else if(data.status == 400 && data.data.Message == "KO_SECPASS"){
                            this.apiError = risorse("PswNonConforme");
                        }
                        else if(data.status == 401){
                            this.apiError = risorse("PswNonCorretta");
                        }
                        else{
                            this.apiError = risorse("ErrGenMsg");
                        }
                        this.$toast.Error(data.Data); 

                    }
                    else{
                        this.oldPassword = "";
                        this.newPassword = "";
                        this.repNewPassword = ""; 
                        this.showSuccess = true;     
                        this.$toast.success("Password modificata correttamente");    
                    }
                }             
            )
        }    
    }
}
</script>
<style>
.PassNetEqual{
    color: #ff0000;
}
</style>