export default class CambioPasswordData{
    NewPassword;
    RepeatNewPassword;
    OldPassword;

    constructor(newPassword, repeatNetPassword, oldPassword){
        this.NewPassword = Buffer.from(newPassword).toString("base64");
        this.RepeatNewPassword = Buffer.from(repeatNetPassword).toString("base64");
        this.OldPassword = Buffer.from(oldPassword).toString("base64");
    }
}