
import Helpers from "@/helpers.js";
import { baseUrl } from "./vue.config";
import ModelsService from "@/services/models.service.js";
import WebApiService from "@/services/webapi.service.js";

import moment from 'moment';
import 'moment/locale/it';
moment.locale('it');


export const widgetMixin = {
    computed: {
        urlImmagineFull : function(){           
            return process.env.VUE_APP_BASEMEDIA_URL + this.widgetData.UrlImmagine;
        }        
    } 
}

export const imgMixin = {
    methods: {
        getUrlImmagineFull(urlImmagine){           
            if(urlImmagine){                
                return  process.env.VUE_APP_BASEMEDIA_URL + urlImmagine;
            }
            else{
                return  baseUrl + 'images/CressiNoPhoto.png'
            }
        }
    }
}

export const priceMixin = {
    methods: {
        formatNumber: function(number, decimals, dec_point, thousands_sep){
            return Helpers.number_format(number, decimals, dec_point, thousands_sep);
        }
    }
}

export const EmailMixin = {
    methods: {
        validateEmail: function(email){
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }
    }
}

export const modelMixin = {
    methods: {
        getModels: function(token, catalogoSelected, categoriaSelected, emitEvent = true){
            ModelsService.filterModels(token, "", catalogoSelected, categoriaSelected).then(
                data => {                                             
                    if(!data){
                        this.$router.push("/");
                    }
                    else if(data && data.length == 0 && emitEvent) {
                        this.$emit("ListModelReady", ["no-models"]);
                    }
                    else{
                        this.listaModelli = data;
                        if(emitEvent){
                            this.$emit("ListModelReady", this.listaModelli);
                        }                        
                    }
                }
            ).catch(
                (err) => {console.log(err)}
            )
        },         

    }
}

export const valutaMixin = {
    computed: {
        simboloValuta: function(){
            return this.$store.getters.getAnagrafica.SimboloValuta
        },
        codiceValuta: function(){
            return this.$store.getters.getAnagrafica.Valuta;
        },
        toEUR: function(){
            return (value) => {
                if (typeof value !== "number") {
                    return value;
                }
                const formatter = new Intl.NumberFormat('it-IT', {
                    style: 'currency',
                    currency: 'EUR',
                    roundingMode : 'ceil'
                });
                return formatter.format(value);
            }
        },
    }
}


export const risorseMixin = {
    methods: {
        risorse: function(data){
            let res = this.$store.getters.getResources('IT');
            if ( res && res[data] ) {
                return res[data]
            } else {
                return data
            }
        }      
    }
}

export const userMixin = {
    computed: {
        NomeUtente: function(){
            let Utente = this.$store.getters.getAnagraficaUtente;
            return Utente["Nome"];
        },
        CognomeUtente: function(){
            let Utente = this.$store.getters.getAnagraficaUtente;
            return Utente["Cognome"];
        },
        ListaSocietaTesserati: function(){
            let Utente = this.$store.getters.getAnagraficaUtente;
            let societa = this.$store.getters.getSocieta;
            var l = [];
            if(Utente?.Tesserati != null){
                Utente?.Tesserati.forEach(t => {
                    if(t.Attivo == 1){
                        let idx = l.findIndex( lt => {
                            return lt.CodSocieta == t?.CodiceSocieta;
                        });
                        if(idx == -1 && societa != null){
                            l.push({
                                CodSocieta: t?.CodiceSocieta,
                                DescrSocieta: societa[societa.findIndex( s =>{ return s?.CodiceAzienda == t?.CodiceSocieta})]?.Descrizione
                            });
                        }
                    }
                });
                return l;
            }else{
                return null;
            }
        },
        tesseratiLenght: function(){
            let tesserati = this.$store.getters.getAnagraficaUtente?.Tesserati;
            if(tesserati != null){
                return tesserati.length;
            }else{
                return 0;
            }
        },
        NomeTesserato: function(){           
            let Utente = this.$store.getters.getAnagraficaUtente;
            return Utente["NomeTesserato"];
        },
        CognomeTesserato: function(){
            let Utente = this.$store.getters.getAnagraficaUtente
            return Utente["CognomeTesserato"];
        },
        SocietaTesserato: function(){
            let Utente = this.$store.getters.getAnagraficaUtente
            return Utente["Societa"];
        },
        NomeSocieta: function(){
            let Azienda = this.$store.getters.getAnagraficaAzienda
            return Azienda.Anagrafica["RagioneSociale"];
        },
        ModoPagamento: function(){
            let Azienda = this.$store.getters.getAnagraficaAzienda
            return Azienda.Anagrafica["ModoPagamento"];
        },
        /*CodiceSocieta: function(){
            let Codice = this.$store.getters.getAnagraficaUtente.CodiceSocieta;
            let Azienda = this.$store.getters.getAnagraficaAzienda;
            if ( Azienda ){
                Codice = this.$store.getters.getAnagraficaAzienda.Anagrafica.CodiceCliente;
            }
            return Codice.padStart(4, '0');
        },*/
        CodiceSocieta2Cifre: function(){
            let Codice = this.$store.getters.getAnagraficaUtente.CodiceSocieta;
            let Azienda = this.$store.getters.getAnagraficaAzienda;
            if ( Azienda ){
                Codice = this.$store.getters.getAnagraficaAzienda.Anagrafica.CodiceCliente;
            }
            return Codice;
        },
        isB2B: function(){
            let Azienda = this.$store.getters.getAnagraficaAzienda
            if ( Azienda ){
                return true;
            } else {
                return false;
            }
        }

    }
}

export const cartMixin = {
    computed: {
        totalCart: function(){
            return this.$store.getters.getPrezzoTotale;
        }
    },
}

export const apiMixin = {

    methods:{
        doApiCall: function(method, url, token, params = null, data= null, lang = null){
            if(method == "get"){
                return WebApiService.get(url, token, params).then(                 
                    (data) => {                                   
                        if(data.StatusCode == 401){
                            this.$router.push("/");
                        }
                        else if (data.StatusCode == 200){
                            return data.Data;
                        }
                        else{                            
                            console.log(data.Message);
                            return data.Message;
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
            if(method == "getWLang"){
                return WebApiService.getWLang(url, token, params, lang).then(                 
                    (data) => {                                        
                        if(data.StatusCode == 401){
                            this.$router.push("/");
                        }
                        else if (data.StatusCode == 200){
                            return data.Data;
                        }
                        else{                            
                            console.log(data.Message);
                            return data.Message;
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
            if(method == "getWLangApiKey"){
                return WebApiService.getWLangApiKey(url, token, params, lang).then(                 
                    (data) => {                                        
                        if(data.StatusCode == 401){
                            this.$router.push("/");
                        }
                        else if (data.StatusCode == 200){
                            return data.Data;
                        }
                        else{                            
                            console.log(data.Message);
                            return data.Message;
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
            if(method == "getImg"){
                return WebApiService.getImage(url, token, params).then(
                    (data) => { 
                        if(data.status == 401){
                            this.$router.push("/");
                        }
                        else if (data.status == 200){                           
                            return data.data;
                        }
                        else{                            
                            console.log(data.Message);
                            return null;
                        }                      
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
            if(method == "post"){
                return WebApiService.post(url, token, data, params).then(
                    (data) => {  
                        if(data.StatusCode == 401){
                            this.$router.push("/");
                        }
                        else if (data.StatusCode == 200){
                            return data;
                        }
                        else{    
                            return data;
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
            if(method == "postWLang"){
                return WebApiService.postWLang(url, token, data, params, lang).then(
                    (data) => {  
                        if(data.StatusCode == 401){
                            this.$router.push("/");
                        }
                        else if (data.StatusCode == 200){
                            return data;
                        }
                        else{         
                            return null;
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
            if(method == "postWLangApiKey"){
                return WebApiService.postWLangApiKey(url, token, data, params, lang).then(
                    (data) => {  
                        if(data.StatusCode == 401){
                            this.$router.push("/");
                        }
                        else if (data.StatusCode == 200){
                            return data;
                        }
                        else{         
                            return null;
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
            if(method == "postNoAuth"){
                return WebApiService.postNoAuth(url, data).then(
                    (data) => {                                   
                        if(data.status == 401){
                            this.$router.push("/");
                        }
                        else if (data.StatusCode == 200){                       
                            return data;
                        }
                        else{                            
                            console.log(data.Message);
                            return null;
                        }                      
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }

            if(method == "postImage"){
                return WebApiService.postImage(url, token, params).then(
                    (data) => {                                   
                        if(data.status == 401){
                            this.$router.push("/");
                        }
                        else if (data.status == 200){                           
                            return data.data;
                        }
                        else{                            
                            console.log(data.Message);
                            return null;
                        }                      
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
        }
    }
}
export const dateMixin = {
    methods:{
        formatDate: function(date, format = 'DD/MM/YYYY HH:mm') {    
            let d = moment(date).format(format);     
            if(d == "Invalid date")d = "-";
            return d;      
        },
        formatDateNoOre: function(date, format = 'DD/MM/YYYY') {  
            let d = moment(date).format(format);     
            if(d == "Invalid date")d = "-";
            return d;      
        },
        formatIt: function(date){
            let d = moment(date).format('L');
            if(d == "Invalid date")d = "-";
            return d;     
        },
        formatDateNoOreJQuery: function(date, format = 'yyyy-MM-DD') {    
            let d = moment(date).format(format);   
            if(d == "Invalid date")d = "";   
            return d ;      
        },
        getLiteralMonth: function(date, format = 'MMMM'){
            let d = moment(date).format(format);
            if(d === "Invalid date")d = "-";   
            return d;
        },
        getDay: function(date, format = 'DD'){
            let d = moment(date).format(format);
            if(d === "Invalid date")d = "-";
            return d;
        },
        getYear: function(date, format = 'YYYY'){
            let d = moment(date).format(format);
            if(d === 'Invalid date')d = "-";
            return d;
        }
    }
}

export const errorMixin = {
    methods: {
        alterError:function(element,val){
            if(val){
                document.getElementById(element).classList.add('errorBorder');
            }else{
                document.getElementById(element).classList.remove('errorBorder');
            }
        }
    }
}