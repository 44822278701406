import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;

class ModelsService{

    async filterModels(token, searchText, searchCatalog, searchCategoria = ""){
        return axios({
            method: 'get',
            url: API_URL + 'modelli/FilterModelli',
            params: {
                searchText: searchText,
                codCategoria: searchCategoria,
                idCatalogo: searchCatalog
            },
            headers:{
                Authorization: 'Bearer '+ token,
                'Content-Type': 'application/json'
            }
        }).then(
            data =>  {               
                if(data.status == 401){
                    return null;
                }
                else{
                    return data.data;
                }               
            }
        )
        .catch(error => {return error;});   

    }

    async getModellibyCategoria(token, idCategoria){
        return axios({
            method: 'get',
            url: API_URL + 'modelli/FilterModelli',
            params: {
                searchText: "",
                codCategoria: idCategoria,
                idCatalogo: ""
            },
            headers:{
                Authorization: 'Bearer '+ token,
                'Content-Type': 'application/json'
            }
        }).then(
            data =>  {               
                if(data.status == 401){
                    return null;
                }   
                else{
                    return data.data;
                }               
            }
        )
        .catch(error => {return error;});   
    }

    async getModelById(token, idModello, tipiAllegato){
        return axios({
            method: 'get',
            url: API_URL + 'modelli/GetModelloById',
            params: {              
                idModello: idModello,
                tipiAllegato: tipiAllegato
            },
            headers:{
                Authorization: 'Bearer '+ token,
                'Content-Type': 'application/json'
            }
        }).then(
            data =>  {               
                if(data.status == 401){
                    return null;
                }
                else{
                    return data.data;
                }               
            }
        )
        .catch(error => {return error;});

    }
}

export default new ModelsService();