module.exports = {
    baseUrl: process.env.VUE_APP_DIR_DEPLOY,
    devServer: {
        proxy: 'studiosport.nubesshub.com'
    },  
    transpileDependencies: true,
    pwa: {
        name: 'Studio Sport',
        themeColor: '#4DBA87',
        msTileColor: '#000000',
        appleMobileWebAppCapable: 'yes',
        appleMobileWebAppStatusBarStyle: 'black',
        workboxPluginMode: 'GenerateSW',
        icons:[
            {
                src: "/android-chrome-192x192",
                sizes: "96x96",
                type: "image/png"
            },   
            {
                src: "/android-chrome-512x512",
                sizes: "96x96",
                type: "image/png"
            }
        ]
    }
}